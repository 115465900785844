@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'lato-bla';
    font-style: normal;
    src: url('../public/assets/fonts/lato-bla.ttf') format('truetype');
    font-weight: bold;
  }
  @font-face {
    font-family: 'lato-bla';
    font-style: normal;
    src: url('../public/assets/fonts/lato-reg.ttf') format('truetype');
    font-weight: normal;
  }
}
